<template>
	<div>
		<div class="mh100 bg-white boderra8 box-shadow0010">
			<div class="align-items-center">
				<div class="h54 align-items-center">
					<div class="title-item center fz16 cure-pointer h100"
						:class="titleIndex == i ? 'co-010085' : 'co-333333'" v-for="(item, i) in titleList" :key="i"
						@click="titleChoose(1, i)">{{item}}</div>
				</div>
				<div class="middle" v-if="titleIndex != 3"></div>
				<div class="h54 align-items-center" v-if="titleIndex != 3">
					<div class="title-item center fz16 cure-pointer h100"
						:class="titleIndexTwo == i ? 'co-010085' : 'co-333333'" v-for="(item, i) in titleTwoList"
						:key="i" @click="titleChoose(2, i)">{{item}}</div>
				</div>
			</div>

			<!-- 课程/素材 -->
			<div class="mart4 paddlr30" v-if="titleIndex !=3">
				<div class="list-item marb10" v-for="(item, i) in list" :key="i" @click="toDetail(item)">
					<div class="paddlr15 h44 fz16 co-333333 justify-content-center-between bg-F7F7F7">
						<span>订单号：{{item.order_no}}</span>
						<span>{{item.create_time}}</span>
					</div>
					<div class="padd15 bg-white display-flex">
						<img class="display-block order-pic boderra8" :src="item.goods_image" alt="" />
						<div class="flex-1 marl20 justify-content-center-between">
							<div class="h104 justify-content-between flex-column">
								<div>
									<div class="fz20 co-333333 font-blod name one-point">{{item.title}}</div>
									<div class="fz14 co-333333 mart12">{{item.order_type_text}}</div>
								</div>
								<div class="align-items-center">
									<span class="fz20 co-010085">￥{{item.good_price}}</span>
									<span class="fz16 co-999999 display-block marl20">在线支付</span>
								</div>
							</div>
							<div class="fz16 co-333333">x{{item.num || 1}}</div>
							<div class="fz16"
								:class="item.status == 1? 'co-FE3000' : item.status == 2 ? 'co-32B16C' : 'co-333333'">
								{{item.status_text}}
							</div>
							<div>
								<div class="btn-01 center fz14 co-010085 marb10" @click.stop='buy(item)'
									v-if="item.status == 1">立即购买</div>
								<div class="btn-02 center fz14 co-333333" v-if="item.status == 2 || item.status == 3"
									@click.stop="cancel($event, 3, i)">删除订单</div>
								<div class="btn-01 center fz14 co-010085 marb10"
									v-if="item.status == 4 && titleIndex == 1" @click.stop="cancel($event, 4, i,item)">
									课程评价
								</div>
								<div class="btn-02 center fz14 co-333333" v-if="item.status == 1"
									@click.stop="cancel($event, 1, i)">取消订单</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 讲师 -->


			<!-- 合同 -->
			<div class="mart4 paddlr30 flex-wrap" v-if="titleIndex == 3">
				<div class="contract-list marr32 marb30" v-for="(item, i) in list" :key="i">
					<div @click="toBig(item.goods_image)">
						<img class="contract-item-pic display-block" :src="item.goods_image" />
					</div>
					<div class="fz20 co-333333 font-blod paddb50 paddl15 paddr15">{{item.title}}</div>
				</div>
			</div>
			<loadMore :state="state" loading_icon></loadMore>
			<Preview ref="Preview"></Preview>
		</div>

		<div class="center paddt54 paddb15">
			<el-pagination background  class="center "  @current-change="handleCurrentChange"
			    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
			</el-pagination>
		</div>
		<el-dialog title="选择支付方式" v-model="dialogFormVisible" :center='true' :width='600'>
			<div class="display-flex">
				<div class="flex-1 flex-column align-items-center justify-content-center cure-pointer"
					@click="choosepay(i)" v-for="(e,i) in wayList" :key="i">
					<img class="head-pic " :src="e.icon" alt="" />
					<div class="#co-333333 fz16 mart10">
						{{e.txt}}
					</div>
				</div>

			</div>
		</el-dialog>
		<el-dialog title="微信支付" v-model="pay" :center='true' :width='600'>
			<div class="puops">
				<div class="qrcode-box" ref="payqrcodes"></div>
				<p>请使用手机打开微信扫描二维码完成支付</p>
			</div>
		</el-dialog>
		<!-- 确认取消弹窗 -->
		<SureCancelPopup ref="SureCancelPopup" @sureBtn="sureBtn"></SureCancelPopup>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>

</template>

<script>
	import Preview from "@/components/Preview.vue";
	import QRCode from 'qrcodejs2';
	import {
		getCurrentInstance,
		ref,
		reactive,
		watch
	} from 'vue';
	export default {
		components: {
			Preview
		},
		setup() {
			const req1 = getCurrentInstance();
			const request = req1.appContext.config.globalProperties;
			let WxLogInData = reactive({
				data: {
					appid: "",
					redirect_uri: encodeURIComponent(),
				}
			})
			return {
				request,
				WxLogInData,

			};
		},
		data() {
			return {
				pay: false,
				dialogFormVisible: false,
				fullscreenLoading: false, //支付加载
				titleList: ['课程', '老师', '素材', '合同'], // 收藏导航
				titleTwoList: ['全部', '待付款', '交易成功', '已取消'], // 订单状态
				titleIndex: 0, // 导航选中
				titleIndexTwo: 0, // 订单状态选中
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				btnStyle1: {
					buttonText: '立即购买'
				},
				delIndex: -1, // 删除选中下标
				dataType: 'course',
				wayList: [
					{
						txt: '微信支付',
						icon: require('@/assets/img/sub_02.png'),
						value: 1,
					},
					// {
					// 	txt: '支付宝支付',
					// 	icon: require('@/assets/img/sub_01.png'),
					// 	value: 2,
					// },
				],
				order_id: "", //查询订单
				oredrid: "",
				size: 10,
				maxnum:'' ,//总条数
			}
		},
		created() {
			this.getData('no')
		},
		methods: {
			toBig(url) {
				this.$refs.Preview.bigImgShow(url)
			},
			buy(item) {
				this.oredrid = item.id;
				this.dialogFormVisible = !this.dialogFormVisible;
			},
			choosepay(e) {
				this.dialogFormVisible = !this.dialogFormVisible;
				this.fullscreenLoading = true;
				this.way = e;
				this.$req({
					method: 'post',
					data: {
						order_id: this.oredrid,
						pay_type: this.wayList[this.way].value,
						trade_type: 3
					},
					url: '/api/mapleOrder/pay',
					success: res => {
						this.fullscreenLoading = false;
						if (res) {
							let url = res.code_url; // 微信支付链接
							console.log(url)
							this.order_id = res.order_id
							if (this.wayList[this.way].value == 1) {
								this.pay = true;
								window.requestAnimationFrame(() => {
									this.fullscreenLoading = false;
									this.$refs.payqrcodes.innerHTML = '';
									//支付二维码
									let qrcode = new QRCode(this.$refs.payqrcodes, {
										text: url, // 需要转换为二维码的内容
										width: 150,
										height: 150,
										colorDark: '#000000',
										colorLight: '#ffffff',
										correctLevel: QRCode.CorrectLevel.H
									})
									this.getOrderStatus(); // 获取订单详情																
								})
							} else {
								this.fullscreenLoading = false;
								let html = res;
								console.log(html)
								const div = document.createElement('divform');
								div.innerHTML = html;
								document.body.appendChild(div);
								// document.forms[0].acceptCharset='GBK'; //保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
								document.forms[0].submit();

							}

						}
					},
					fail: error => {
						this.pay = !this.pay;
						this.fullscreenLoading = false;

					}
				});
			},

			getData(type) {
				if (this.loading) return
				this.page = ++this.page
				if (this.page == 1) {
					this.state = 1
					if (type != 'no') {
						this.list = []
					}
				}
				this.$req({
					method: 'get',
					url: '/api/mapleOrder/index',
					data: {
						type: this.dataType,
						status: this.titleIndexTwo == 0 ? '999' : this.titleIndexTwo == 3 ? '0' : this
							.titleIndexTwo,
						page: this.page,
						size: 10,
					},
					success: res => {
						this.loading = false
						console.log(res, "订单列表")
						if (this.page == 1) {
							this.list = res
						} else {
							this.list = this.list.concat(res)
						}
						if (res.length != 0 && res.length < 10) {
							this.state = 2
						} else if (this.list.length == 0) {
							this.state = 3
						}

					},
					fail: error => {}
				});
				//总条数
				this.$req({
					method: 'get',
					data: {
						type: this.dataType,
						status: this.titleIndexTwo == 0 ? '999' : this.titleIndexTwo == 3 ? '0' : this.titleIndexTwo,
						show_page:1,
						
					},
					url: '/api/mapleOrder/index',
					success: res => {
						console.log(res,'总条数')
						this.maxnum =res;
					},
					fail: error => {
						
					}
				});
			},
			getOrderStatus() {

				this.$req({
					method: 'get',
					url: '/api/mapleOrder/detail',
					data: {
						id: this.order_id
					},
					success: res => {
						let data = res;
						if (data.status > 1) {
							this.$message.success("支付成功");
							this.pay = false;
							this.speedIndex = 3;
							window.requestAnimationFrame(() => {
								this.request.tokenSuccess();
							})
							location.reload();
						} else if (data.status < 0) {
							this.pay = false;
							this.$message.warning("订单已取消");
						} else if (this.pay) {
							let a = setTimeout(() => {
								clearTimeout(a);
								this.getOrderStatus(); // 获取订单详情
							}, 2000)
						}
					},
					fail: error => {

					}
				});
			},
			// 订单按钮操作
			cancel(e, type, i, item) {
				if (type == 1) {
					this.$confirm('确定取消此订单么?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$req({
							method: 'put',
							url: '/api/mapleOrder/close',
							data: {
								id: this.list[i].id,
							},
							success: res => {
								if (this.titleIndexTwo == 0) {
									this.list[i].status = 0
									this.list[i].status_text = '已取消'
								} else {
									this.list.splice(i, 1)
								}
								this.$message({
									type: 'success',
									message: '取消成功!'

								});
							},
							fail: error => {}
						});

					}).catch(() => {

					});
				} else if (type == 3) {
					this.$confirm('确定删除此订单么?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$req({
							method: 'delete',
							url: '/api/mapleOrder/delete',
							data: {
								id: this.list[i].id,
							},
							success: res => {
								this.$message({
									type: 'success',
									message: '删除成功!'

								});
								this.list.splice(i, 1)
							},
							fail: error => {}
						});

					}).catch(() => {

					});


				} else if (type == 4) {
					// 课程评价
					// 携带评价参数
					this.$router.push({
						path: '/OrderDetail',
						query: {
							id: item.id
						}
					})
				}

				e.stopPropagation()
			},
			// 导航选中
			titleChoose(e, i) {
				if (this.loading) return
				if (e == 1) {
					this.titleIndex = i;
					this.titleIndexTwo = 0;
					if (i == 0) {
						this.dataType = 'course'
					} else if (i == 1) {
						this.dataType = 'teacher'
					} else if (i == 2) {
						this.dataType = 'source'
					} else if (i == 3) {
						this.dataType = 'contract'
					}
					if (i == 0 || i == 2) {
						this.titleListTwo = ['全部', '待付款', '交易成功', '已取消']
						this.list = [{
							status: 1,
							status_text: '待付款'
						}, {
							status: 2,
							status_text: '交易成功'
						}, {
							status: 3,
							status_text: '已取消'
						}]
					} else if (i == 1) {
						this.titleListTwo = ['全部', '待付款', '交易成功', '已取消']
						this.list = [{
							status: 1,
							status_text: '待付款'
						}, {
							status: 2,
							status_text: '已完成'
						}, {
							status: 3,
							status_text: '已取消'
						}, {
							status: 4,
							status_text: '正在进行'
						}, {
							status: 5,
							status_text: '待评价'
						}]
					}
				} else {
					this.titleIndexTwo = i
				}
				this.page = 0
				this.getData()
			},
			// 跳转详情
			toDetail(item) {

				this.$router.push({
					path: '/OrderDetail',
					query: {
						id: item.id
					}
				})
			},
			handleCurrentChange(val) {
			   this.page = val;
			   this.list = [];
			   this.loading = false;
			   this.getData()
			    document.documentElement.scrollTop = document.body.scrollTop = 400;
			},
		}
	}
</script>

<style lang="less" scoped="scoped">
	.puops {
		display: flex;
		align-items: center;
		min-height: 316px;
		flex-direction: column;

		h2 {
			width: 100%;
			text-align: center;
			font-size: 20px;
			font-weight: 500;
			color: #131313;
			border-bottom: 1px solid #d7dae2;
			padding-bottom: 22.5px;
		}

		.order_pay {
			display: flex;
			flex-direction: column;
			background-color: #e5e5e5;
			width: 100%;
			padding: 21px 35px;
		}

		.qrcode-box {
			margin: 40px 0;
			width: 150px;
			height: 150px;
			background-color: #f9f9f9;
			overflow: hidden;

			canvas+img {
				width: 100%;
				height: 100%;
			}
		}

		p {
			font-size: 19px;
			font-weight: 500;
			color: #E84516;
			opacity: 0.5;
		}
	}

	.head-pic {
		width: 60px;
		height: 60px;
	}

	.hiddle {
		visibility: hidden;
	}

	.title-item {
		padding: 0 30px;
	}

	.middle {
		width: 0px;
		height: 24px;
		border: 1px dashed #CCCCCC;
		margin: 0 20px;
	}

	.list-item {
		border: 1px solid #E6E6E6;

		.order-pic {
			width: 140px;
			height: 104px;
		}

		.name {
			max-width: 300px;
		}

		.btn-01 {
			width: 72px;
			height: 28px;
			background: #DCFF03;
			opacity: 1;
			border-radius: 3px;
		}

		.btn-02 {
			width: 70px;
			height: 26px;
			border: 1px solid #999999;
			border-radius: 3px;
		}
	}

	.list-item:nth-child(2n) {
		margin-right: 0;
	}

	.list-item:hover {
		border: 1px solid #DCFF03;
		box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
		cursor: pointer;
	}

	.label-item {
		padding: 0 10px;
		height: 28px;
		background: rgba(50, 177, 108, 0.12);
		border-radius: 4px;
	}

	.contract-list {
		width: 283px;
		border: 1px solid #E6E6E6;
		border-radius: 8px;

		.contract-item-pic {
			width: 285px;
			height: 220px;
			border-radius: 8px 8px 0px 0px;
		}
	}

	.contract-list:nth-child(3n) {
		margin-right: 0;
	}
</style>
